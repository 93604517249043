import React from "react";
import { GrowthPageTemplate } from "./PageTemplate";
import { Box, FlexCol, FlexRow } from "../styledSystemUtilities";
import { GrowthHeader, GrowthSubHeader } from "../Atoms/Headers";
import { Text } from "../Atoms";
import { StartFromLeftPinPlacement } from "../Atoms/CompetitionLevelIndicator";
import _ from "lodash/fp";
// import { report } from "./data";
import { ReverseGradient } from "../Atoms/Gradient";
import { UpsideDownPin } from "../Atoms/assets";
import { centsFormatter, formatter } from "../../utils/formatter";
import { ppcAverages } from "../../constants/ppcAverages";
import { useSelector } from "react-redux";

export const GrowthOne = () => {
    const report = useSelector((state) => state.report);

    const cpc = _.get("ppc.keywords.0.cpc", report);
    const highCpc = ppcAverages.ascentAverages.cpc * 2;

    return (
        <GrowthPageTemplate hideSummary={true}>
            <Box pb={"24px"} />
            <GrowthHeader textAlign={"center"}>Your Data Path</GrowthHeader>
            <GrowthSubHeader>{_.get("user.practiceName", report)}</GrowthSubHeader>
            <Box pb={"17px"} />
            {/*<CircleHeader*/}
            {/*    number={1}*/}
            {/*    color={"petrolBlue"}*/}
            {/*    text={"Can a marketing company accurately predict campaign outcomes?"}*/}
            {/*/>*/}
            {/*<Box pb={"25px"} />*/}
            <Box pl={"22px"}>
                {/*    <Petrol14>Yes! But only with reliable key points of data along</Petrol14>*/}
                <Text fontFamily={"montserrat"} fontSize={"23px"} color={"ipanemaBlue"}>
                    Your data path:
                </Text>
            </Box>
            <Box pb={"8px"} />
            <WhiteBox number={1} header={"The cost to run a Google ad in your area."}>
                <Text fontSize={"13px"} fontFamily={"roboto"} color={"sienna"}>
                    Your area’s Google Ads price per click:
                </Text>
                <Box width={"100%"}>
                    <Box width={"94%"} pl={"10px"} data-testid={"practicePricePerClick"}>
                        <StartFromLeftPinPlacement
                            percent={((cpc > highCpc ? highCpc : cpc) / highCpc) * 100}
                            score={cpc}
                            formatter={(score) => centsFormatter.format(score)}
                            increment={cpc > 5 ? 0.1 : 0.01}
                            startAnimationTimeout={0}
                        />
                    </Box>

                    <ReverseGradient width={"100%"} height={"10px"} />
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Text variant={"body4"} color={"ipanemaBlue"} pt={"8px"}>
                            Low
                        </Text>
                        <FlexCol alignItems={"center"}>
                            <UpsideDownPin />
                            <Text variant={"header2"} color={"deepPetrol"}>
                                {formatter.format(ppcAverages.ascentAverages.cpc)}
                            </Text>
                            <Text variant={"body4"} color={"ipanemaBlue"} pt={"4px"}>
                                National Average
                            </Text>
                        </FlexCol>
                        <Text variant={"body4"} color={"ipanemaBlue"} pt={"8px"}>
                            High
                        </Text>
                    </Box>
                </Box>
            </WhiteBox>
            <GapLine />
            <WhiteBox number={2} header={"The % who click on the ad and visit the landing page."}>
                <WhiteBoxBody
                    percent={`${ppcAverages.ascentAverages.ctr * 100}%`}
                    body={"Google Ads by Ascent send potential patients to the landing page at an average rate of:"}
                    parenthesis={"(This is way above the national average!)"}
                    data-testid={"ctr"}
                />
            </WhiteBox>
            <GapLine />
            <WhiteBox number={3} header={"The % who call the practice from the landing page."}>
                <WhiteBoxBody
                    percent={`${ppcAverages.ascentAverages.ctc * 100}%`}
                    body={"Landing pages designed by Ascent produce phone calls at an average rate of:"}
                    parenthesis={"(This is an excellent rate for a landing page!)"}
                    data-testid={"ctc"}
                />
            </WhiteBox>
            <GapLine />
            <WhiteBox number={4} header={"The % who book an appointment after making a call."}>
                <WhiteBoxBody
                    percent={`${ppcAverages.ascentAverages.conversionRate * 100}%`}
                    body={"Practices that work with Ascent convert inbound Google Ads calls at an average rate of:"}
                    parenthesis={"(This is a great rate for Google Ads calls!)"}
                    data-testid={"conversionRate"}
                />
            </WhiteBox>
            <GapLine />
            <Petrol14 pl={"34px"} pr={"22px"} mt={"-8px"}>
                Our reliable data path allows us to project a likely outcome and cost for a campaign in your area.
            </Petrol14>
            <Box pb={"12px"} />
            <Petrol14 px={"22px"}>
                Our numbers speak for themselves. Ascent is simply better at getting the most out of your campaign.
            </Petrol14>
            {/*<Box pb={"16px"} />*/}
            {/*<Button backgroundColor={"ipanemaBlue"} onClick={() => routerHistory.push("/growth-two")}>*/}
            {/*    continue*/}
            {/*</Button>*/}
            <Box pb={"100px"} />
        </GrowthPageTemplate>
    );
};

const WhiteBox = ({ number, children, header }) => (
    <FlexRow backgroundColor={"white"} pl={"14px"} pt={"12px"} pr={"20px"}>
        <FlexCol alignItems={"center"} width={"25px"}>
            <Box
                height={"25px"}
                width={"25px"}
                border={`1px solid #1A7888`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={"50%"}
            >
                <Text fontWeight={"medium"} fontFamily={"roboto"} fontSize={"14px"} color={"petrolBlue"}>
                    {number}
                </Text>
            </Box>
            <Box width={"1px"} backgroundColor={"deepPetrol"} flexGrow={1} />
        </FlexCol>
        <Box pl={"8px"} pt={"6px"} pb={"31px"} flexGrow={1}>
            <Text fontSize={"13px"} fontFamily={"roboto"} fontWeight={"medium"} color={"petrolBlue"}>
                {header}
            </Text>
            <Box pb={"18px"} />
            <FlexCol alignItems={"center"} textAlign={"center"}>
                {children}
            </FlexCol>
        </Box>
    </FlexRow>
);

const WhiteBoxBody = ({ body, percent, parenthesis, ...props }) => (
    <>
        <Ipanema13>{body}</Ipanema13>
        <Box pb={"6px"} />
        <Text variant={"header2"} color={"deepPetrol"} {...props}>
            {percent}
        </Text>
        <Box pb={"6px"} />
        <Ipanema13>{parenthesis}</Ipanema13>
    </>
);

const GapLine = () => (
    <>
        <Box position={"absolute"} zIndex={2}>
            <Box height={"19px"} width={"1px"} backgroundColor={"deepPetrol"} ml={"26px"} />
            <Box width={"5px"} height={"5px"} borderRadius={"50%"} backgroundColor={"deepPetrol"} ml={"24px"} />
        </Box>
        <Box pb={"21px"} />
    </>
);

const Petrol14 = ({ children, ...props }) => (
    <Text variant={"body3"} fontWeight={"medium"} color={"petrolBlue"} lineHeight={"19px"} {...props}>
        {children}
    </Text>
);

const Ipanema13 = ({ children }) => (
    <Text fontSize={"13px"} lineHeight={"18px"} color={"ipanemaBlue"}>
        {children}
    </Text>
);
